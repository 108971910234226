.sbl-ticker-result {
	color: #4f98e6;
}
.blue-compact-btn {
	background: linear-gradient(90deg, #004080 0%, #1c86ee 100%);
	color: #fff;
	border: none;
	padding: 3px 10px;
	border-radius: 3px;
	cursor: pointer;
	font-size: 12px;
	float: right;
}
.blue-compact-btn:hover {
	background: linear-gradient(90deg, #005a9e 0%, #1e90ff 100%);
}

/**********Scroll Bar accross MA**************/
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(112, 112, 112, 0.8);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(112, 112, 112, 0.8);
}
